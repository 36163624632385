import { AuthState } from '@frontegg/redux-store'
import { nestStandardAccountId } from 'utils/CareTemplateUtil'

export enum Role {
  Patient = 'Patient',
  Admin = 'Admin',
  Portal = 'Portal',
  Delegate = 'Delegate',
}

function hasRole(user: AuthState['user'], role: keyof typeof Role) {
  return user?.roles?.some(
    ({ key }) => key?.toLowerCase() === role?.toLowerCase(),
  )
}

export function hasPatientRole(user: AuthState['user']) {
  return hasRole(user, 'Patient')
}

export function hasAdminRole(user: AuthState['user']) {
  return hasRole(user, 'Admin')
}

export function hasPortalRole(user: AuthState['user']) {
  return hasRole(user, 'Portal')
}

export function hasDelegateRole(user: AuthState['user']) {
  return hasRole(user, 'Delegate')
}

export function hasPortalRoleAccess(user: AuthState['user']) {
  return (
    hasAdminRole(user) || hasPortalRole(user) || hasDelegateRole(user)
  )
}

export function isPatientUser(user: AuthState['user']) {
  return hasPatientRole(user) && !hasPortalRoleAccess(user)
}

export function checkNestStandardAccount(user: AuthState['user']) {
  return user.tenantId === nestStandardAccountId
}

export const UserUtil = {
  hasAdminRole,
  hasDelegateRole,
  hasPatientRole,
  hasPortalRole,
  hasPortalRoleAccess,
  isPatientUser,
}

export default UserUtil
