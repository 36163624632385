import { useAuth, useLoginActions } from '@frontegg/react'
import { throttle } from 'lodash'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { useDocumentEventListener, useIntervalWhen } from 'rooks'
import { Role } from 'utils/UserUtil'

const INACTIVITY_DIALOG_THRESHOLD = 60000 * 5 // 5 min
let isRedirecting = false

export enum StoreKey {
  LAST_ACTIVITY = 'lastActivity',
  REDIRECT_AFTER_LOGIN = 'redirectAfterLogin',
  SCHEDULED_LOGOUT = 'scheduledLogout',
}

export default function useAutoLogout(
  // Duration in ms before user is logged out automatically.
  activityExpiration: number,
  disabled?: boolean,
) {
  const { logout: fronteggLogout } = useLoginActions()
  const { isAuthenticated, user } = useAuth()
  const hasPortalRoleAccess = user?.roles.some((role) =>
    [Role.Admin, Role.Portal, Role.Delegate].includes(
      role.key as Role,
    ),
  )
  const router = useRouter()
  const getLastActivity = useCallback((): number | null => {
    let lastActivity: string | number = localStorage.getItem(
      StoreKey.LAST_ACTIVITY,
    )

    if (lastActivity) {
      lastActivity = parseInt(lastActivity, 10)
    }
    if (!lastActivity) {
      return null
    }

    return lastActivity as number
  }, [])
  const isInactive = useCallback(() => {
    const lastActivity = getLastActivity()

    // No value means no recorded activity in this case.
    if (!lastActivity) {
      return false
    }

    const timeSinceLastActivity = Date.now() - lastActivity

    return timeSinceLastActivity > activityExpiration
  }, [activityExpiration, getLastActivity])
  const shouldOpenInactiveDialog = useCallback(() => {
    const lastActivity = getLastActivity()

    // No value means no recorded activity in this case.
    if (!lastActivity) {
      return false
    }

    const timeSinceLastActivity = Date.now() - lastActivity

    return (
      timeSinceLastActivity <= activityExpiration &&
      activityExpiration - timeSinceLastActivity <=
        INACTIVITY_DIALOG_THRESHOLD
    )
  }, [activityExpiration, getLastActivity])
  const [isTrackingActivity, setIsTrackingActivity] = useState(false)
  const logout = useCallback(() => {
    setIsTrackingActivity(false)
    setTimeout(() => {
      abandonScheduledLogout()
      fronteggLogout()
    })
  }, [fronteggLogout])
  const [isSessionInactive, setIsSessionInactive] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const scheduleLogout = useCallback(
    (reason: string) => {
      if (isSessionExpired) {
        // Otherwise toggling a pending logout multiple times may cause re-renders.
        return
      }

      console.warn(`Schedule logout: ${reason}`)
      setIsTrackingActivity(false)
      setIsSessionInactive(false)
      setIsSessionExpired(true)
      if (!disabled) {
        localStorage.setItem(StoreKey.SCHEDULED_LOGOUT, 'true')
      }
    },
    [disabled, isSessionExpired],
  )
  const [
    isActivityMonitoringStarted,
    setIsActivityMonitoringStarted,
  ] = useState(false)
  const inactiveCheck = useCallback(() => {
    // Inactivity checks may happen:
    // - while there's no user authenticated
    // - before a starting activity event will be processed
    // - when a logout dialog is being shown.
    if (
      !isAuthenticated ||
      !isActivityMonitoringStarted ||
      isSessionExpired
    ) {
      return
    }

    if (isInactive()) {
      scheduleLogout('inactive check')
    } else if (shouldOpenInactiveDialog()) {
      setIsSessionInactive(true)
    }
  }, [
    isAuthenticated,
    isActivityMonitoringStarted,
    isSessionExpired,
    isInactive,
    shouldOpenInactiveDialog,
    scheduleLogout,
  ])
  const throttledHandleActivity = throttle((e?: Event) => {
    e?.stopImmediatePropagation()
    if (disabled || !isTrackingActivity) {
      return
    }

    // Store last activity for syncing browsers tabs with the app open and reloading the app.
    localStorage.setItem(StoreKey.LAST_ACTIVITY, `${Date.now()}`)
    setIsActivityMonitoringStarted(true)
  }, 1000)
  const handleActivity = useCallback(throttledHandleActivity, [
    throttledHandleActivity,
  ])
  const isOnFronteggUrl =
    window.location.pathname.startsWith('/account/')

  // On app mount, check if session is valid based on inactivity.
  useEffect(() => {
    if (!disabled && isInactive()) {
      scheduleLogout('inactive check on app mount')
      console.warn('Logout on app mount due to activity expiration')
      logout()
      return
    }

    setIsTrackingActivity(true)
    handleActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Make sure to clear a last activity on the next tick because
  // we have event listeners also relying on isLoggingOut.
  useEffect(() => {
    if (!isTrackingActivity) {
      localStorage.removeItem(StoreKey.LAST_ACTIVITY)
    }
  }, [isTrackingActivity])

  useDocumentEventListener('keydown', handleActivity)
  useDocumentEventListener('mousemove', handleActivity)
  useDocumentEventListener('touchstart', handleActivity)

  // Check inactivity status every 5s when auto-logout is enabled.
  useIntervalWhen(inactiveCheck, 5000, !disabled, !disabled)

  // Redirect for auth sessions.
  useEffect(() => {
    const handleAuthRedirect = async () => {
      // Navigate to redirect url for deep linking.
      if (isAuthenticated && hasPortalRoleAccess) {
        const redirectUrl = getRedirectAfterLoginUrl({
          query: router.query,
        })

        if (redirectUrl && !isRedirecting) {
          isRedirecting = true

          await router.replace(redirectUrl)
          localStorage.removeItem(StoreKey.REDIRECT_AFTER_LOGIN)
          console.warn(`Redirected to: ${redirectUrl}`)

          isRedirecting = false
          return
        }
      }

      // Save previous route and redirect to login if not authenticated.
      if (!isAuthenticated) {
        // Remove possibly stale values to avoid another double login issue
        // because we're already logged out.
        abandonScheduledLogout()
        if (!isOnFronteggUrl) {
          const redirectUrl =
            window.location.pathname + window.location.search

          localStorage.setItem(
            StoreKey.REDIRECT_AFTER_LOGIN,
            redirectUrl,
          )
          console.warn(`Scheduled redirect: ${redirectUrl}`)
          router.replace(`/account/login`)
        }
        return
      }

      // Check for scheduled logout (possibly from other tabs).
      if (
        isAuthenticated &&
        localStorage.getItem(StoreKey.SCHEDULED_LOGOUT) === 'true'
      ) {
        console.warn('Performing scheduled logout')
        logout()
        return
      }
    }

    // Avoid auth redirect for disabled or expired session.
    if (!disabled && !isSessionExpired) {
      handleAuthRedirect()
    }
  }, [
    isAuthenticated,
    router,
    logout,
    disabled,
    isSessionExpired,
    hasPortalRoleAccess,
    isOnFronteggUrl,
  ])

  return {
    logout,
    scheduleLogout,
    sessionIsExpired: isSessionExpired,
    sessionIsInactive: isSessionInactive,
    setSessionIsInactive: setIsSessionInactive,
  }
}

export function abandonScheduledLogout() {
  const wasLogoutScheduled = localStorage.getItem(
    StoreKey.SCHEDULED_LOGOUT,
  )
  localStorage.removeItem(StoreKey.SCHEDULED_LOGOUT)
  localStorage.removeItem(StoreKey.LAST_ACTIVITY)
  if (wasLogoutScheduled) {
    console.warn('Abandoned scheduled logout')
  }
}

export function abandonRedirectAfterLogout() {
  localStorage.removeItem(StoreKey.REDIRECT_AFTER_LOGIN)
}

// Function to get the first value of a query parameter by key
export const getFirstArrayItem = (values: string | string[]) => {
  return Array.isArray(values) ? values[0] : values
}

export const getRedirectAfterLoginUrl = ({
  query,
}: {
  query: ParsedUrlQuery
}) => {
  const redirectAfterLoginQueryParam = getFirstArrayItem(
    query[StoreKey.REDIRECT_AFTER_LOGIN],
  )

  const redirectAfterLoginLocalStorage = localStorage.getItem(
    StoreKey.REDIRECT_AFTER_LOGIN,
  )

  const redirectUrl =
    redirectAfterLoginQueryParam || redirectAfterLoginLocalStorage

  // Temporarily we don't want to redirect to the home page (/)
  return redirectUrl !== '/' ? redirectUrl : null
}
