import React, { ReactNode, Ref, useMemo } from 'react'
import { includes, isNumber } from 'lodash'
import {
  Box,
  BoxProps,
  Checkbox,
  ListSubheader,
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  OutlinedInputProps,
  SelectProps,
  Skeleton,
} from '@mui/material'
import { SelectNumberOption, SelectOption } from 'types/Global'
import { isArray, omit } from 'lodash'

const defaultSx: MuiTextFieldProps['sx'] = {
  '& .MuiCheckbox-root': {
    display: 'none',
  },
  '& .MuiCircularProgress-root': {
    color: 'primary.main',
    maxHeight: 16,
    maxWidth: 16,
  },
  '& .MuiFormHelperText-root': {
    ml: 0,
  },
  '& .MuiInput-input .NestedSelectOption-icon': {
    // Hide nested option arrow on input field.
    display: 'none',
  },
  '& .MuiInput-root.Mui-disabled:before': {
    borderBottomColor: 'action.disabled',
    zIndex: 0,
  },
  '& .MuiInputLabel-root + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline > legend > *':
    {
      // Remove notch in the outlined input.
      display: 'none',
    },
  '& input': {
    '&::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
}
const displaySx: MuiTextFieldProps['sx'] = {
  '& .MuiFormHelperText-root': {
    m: 0,
  },
  '& .MuiFormLabel-root': {
    overflow: 'visible',
  },
  '& .MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
    mr: '.25em',
  },
  '& .MuiInputLabel-root': {
    mb: 0,
  },
  '& .MuiOutlinedInput-input': {
    p: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: 'inherit',
    p: 0,
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}

const readOnlySx: MuiTextFieldProps['sx'] = {
  '& .MuiInput-root .MuiInputBase-input.MuiSelect-select': {
    pr: 0,
  },
  '& .MuiInputBase-root.MuiInput-underline:before': {
    borderColor: 'action.disabled',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'text.secondary',
  },
  '& .MuiOutlinedInput-input': {
    cursor: 'default',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      borderColor: 'action.disabled',
      borderWidth: 1,
    },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'action.disabled',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}

type SelectHeaderOption = {
  title: string
  label?: never
  value?: never
  disabled?: never
  options?: never
}
export type TextFieldSelectOption =
  | ((
      | SelectOption
      | SelectNumberOption
      | {
          label: ReactNode
          value: string
          disabled?: boolean
        }
    ) & {
      title?: never
    })
  | SelectHeaderOption

export type TextFieldProps = Omit<
  MuiTextFieldProps,
  'select' | 'options'
> & {
  autoWidth?: boolean
  asDisplay?: boolean
  loading?: boolean
  select?: never
  options?: never
}

export type SelectFieldProps = Omit<
  TextFieldProps,
  'select' | 'options'
> & {
  select: boolean
  options: TextFieldSelectOption[]
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function handlePreventDefault(e: Event) {
  e.preventDefault()
}

const TextField = React.forwardRef(function TextField(
  props: TextFieldProps | SelectFieldProps,
  ref: Ref<HTMLDivElement>,
) {
  const {
    asDisplay,
    loading,
    autoWidth,
    options,
    ...textFieldProps
  } = props
  const readOnly = asDisplay || !!props.InputProps?.readOnly
  const SelectProps = useMemo(() => {
    if (!props.select) return null
    return {
      ...props.SelectProps,
      displayEmpty: true,
      renderValue: (value) => {
        const multiple = !!props.SelectProps?.multiple
        const displayPlaceholder =
          !isNumber(value) &&
          props.placeholder &&
          (!value || (multiple && !(value as unknown[])?.length))

        // Display placeholder for empty field.
        if (displayPlaceholder) {
          return (
            <Box
              sx={{
                opacity: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {props.placeholder}
            </Box>
          )
        }

        // Allow renderValue override if provided.
        if (props.SelectProps?.renderValue) {
          return props.SelectProps.renderValue(value)
        }

        // Display comma separated labels for multiple select fields.
        if (multiple) {
          return value
            .map((item) => {
              const label = options.find(
                (option) => item === option.value,
              )?.label

              return label
            })
            .filter(Boolean)
            .join(', ')
        }

        // Display default label.
        return options.find((option) => value === option.value)?.label
      },
    }
  }, [options, props.SelectProps, props.placeholder, props.select])
  const hasMenuItemGroups = (options ?? []).some(
    (option) => option.title,
  )
  const TextFieldProps = useMemo<Partial<MuiTextFieldProps>>(
    () => ({
      ...omit(textFieldProps, ['options']),
      InputLabelProps: {
        ...props.InputLabelProps,
        // Label requires an input field and the way we render Select fields using MenuItems produces a native Error
        // Incorrect use of <label for=FORM_ELEMENT>
        component: props.select ? 'div' : undefined,
        disableAnimation: true,
        shrink: true,
      },
      InputProps: {
        name: props.name,
        ...props.InputProps,
        // Disabled notch only for outlined input (default variant).
        notched: [undefined, 'outlined'].includes(props.variant)
          ? false
          : undefined,
        readOnly,
      } as OutlinedInputProps,
      SelectProps: SelectProps,
      fullWidth: props.fullWidth,
      helperText: asDisplay ? null : textFieldProps.helperText,
      required: !asDisplay && props.required,
      size: props.size ?? 'small',
      sx: [
        defaultSx,
        autoWidth && {
          // Delegate width to `before` element.
          '& .MuiInput-root': {
            bottom: '-0.089em',
            position: 'absolute',
          },
          '&::before': {
            content: `"${props.value ?? ''}"`,
            visibility: 'hidden',
          },
        },
        props.multiline && {
          '& .MuiInputBase-input': {
            overflow: 'auto',
            textOverflow: 'ellipsis',
          },
          '& .MuiSelect-select.MuiSelect-multiple': {
            whiteSpace: 'normal',
          },
        },
        props.sx as unknown,
        asDisplay && displaySx,
        readOnly && readOnlySx,
      ],
      value:
        props?.SelectProps?.multiple && !isArray(textFieldProps.value)
          ? [textFieldProps.value].filter(Boolean)
          : textFieldProps.value,
      variant: props.variant,
    }),
    [
      SelectProps,
      asDisplay,
      autoWidth,
      props.InputLabelProps,
      props.InputProps,
      props?.SelectProps?.multiple,
      props.fullWidth,
      props.multiline,
      props.name,
      props.required,
      props.select,
      props.size,
      props.sx,
      props.value,
      props.variant,
      readOnly,
      textFieldProps,
    ],
  )

  if (loading) {
    return (
      <TextFieldSkeleton
        label={props.label}
        asDisplay={asDisplay}
        sx={props.sx}
      />
    )
  }

  return (
    <MuiTextField
      ref={ref}
      {...TextFieldProps}
      InputProps={{
        ...TextFieldProps.InputProps,
        slots: {
          ...TextFieldProps.InputProps?.slots,
          input:
            TextFieldProps.InputProps?.slots?.input ??
            (asDisplay ? StaticInput : undefined),
        },
      }}
      onFocus={(e) => {
        TextFieldProps?.onFocus?.(e)
        if (props.type === 'number') {
          // Disable default browser behavior to increment/decrement the value when scrolling.
          e.target.addEventListener('wheel', handlePreventDefault)
        }
      }}
      onBlur={(e) => {
        TextFieldProps?.onBlur?.(e)
        if (props.type === 'number') {
          // Remove workaround for disabling default browser behavior when scrolling.
          e.target.removeEventListener('wheel', handlePreventDefault)
        }
      }}
    >
      {props.select &&
        options?.map((option, index) =>
          option.title ? (
            <ListSubheader key={`title.${props.name}.${index}`}>
              {option.title}
            </ListSubheader>
          ) : (
            <MenuItem
              key={option.value.toString()}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={option.value as any}
              disabled={option.disabled}
              sx={{
                px: hasMenuItemGroups ? 4 : undefined,
              }}
            >
              {props.SelectProps?.multiple && (
                <Checkbox
                  // A form field element should have an id or name attribute
                  name={`${props.name}.checkbox.${option.value}`}
                  checked={includes(
                    (props.value ?? []) as (
                      | string
                      | number
                      | boolean
                    )[],
                    option.value,
                  )}
                  size="small"
                  disableRipple
                  sx={{
                    ml: -1,
                    py: 0.5,
                  }}
                />
              )}
              {option.label}
            </MenuItem>
          ),
        )}
    </MuiTextField>
  )
})

export default TextField

export type TextFieldSkeletonProps = {
  label?: ReactNode
  asDisplay?: boolean
  sx?: BoxProps['sx']
}

export function TextFieldSkeleton(props: TextFieldSkeletonProps) {
  const { sx, label, asDisplay } = props
  return (
    <Box sx={{ ...sx, width: 1 }}>
      {label && <Skeleton width={60} height="1em" />}
      {asDisplay ? (
        <Skeleton height="1.7em" />
      ) : (
        <Box
          sx={{
            border: ({ palette }) => `solid 1px ${palette.divider}`,
            borderRadius: 1,
            lineHeight: 24,
            mt: 1,
            padding: '8.5px 14px',
          }}
        >
          <Skeleton
            width="66%"
            height={'1.28em'}
            sx={{ maxWidth: 240 }}
          />
        </Box>
      )}
    </Box>
  )
}

const StaticInput = React.forwardRef(function StaticInput(
  props: {
    value: ReactNode
    className: string
    renderValue: SelectProps['renderValue']
  },
  ref: Ref<HTMLDivElement>,
) {
  const { className, renderValue } = props
  const value = renderValue ? renderValue(props.value) : props.value

  return (
    <Box
      ref={ref}
      className={className}
      sx={{ color: !value ? 'text.secondary' : null }}
    >
      {value || '––'}
    </Box>
  )
})
