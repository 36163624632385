import React, { ReactNode } from 'react'
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded'

export type EnhancedDialogProps = Omit<MuiDialogProps, 'onClose'> & {
  disableCloseOnBackdropClick?: boolean
  enableResponsiveFullscreen?: boolean
  onClose?: (event?: React.SyntheticEvent<unknown, Event>) => void
}

export function EnhancedDialog(props: EnhancedDialogProps) {
  const {
    disableCloseOnBackdropClick,
    enableResponsiveFullscreen,
    ...dialogProps
  } = props
  return (
    <MuiDialog
      closeAfterTransition
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        ...dialogProps.TransitionProps,
      }}
      {...dialogProps}
      open={!!props.open}
      onClose={(event, reason) => {
        // Ignore close attempts depending on props.
        if (
          disableCloseOnBackdropClick &&
          reason === 'backdropClick'
        ) {
          return
        }
        props.onClose?.()
      }}
      sx={{
        ...dialogProps.sx,
        ...(enableResponsiveFullscreen && {
          '& .MuiDialog-paper': {
            '@media (max-height: 768px)': {
              m: '0px !important',
              maxHeight: '100vh',
            },
            height: 1,
            transition: 'margin 0.2s ease 0s',
            width: 1,
            ...dialogProps.sx?.['& .MuiDialog-paper'],
          },
        }),
      }}
    />
  )
}

type EnhancedDialogTitleProps = DialogTitleProps & {
  onClose?: () => void
  disableClose?: boolean
  endContent?: ReactNode
}

export function EnhancedDialogTitle(props: EnhancedDialogTitleProps) {
  const {
    children,
    onClose,
    disableClose,
    endContent,
    ...dialogTitleProps
  } = props
  return (
    <DialogTitle {...dialogTitleProps}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Stack sx={{ minWidth: 0, typography: 'h4', width: 1 }}>
          {children}
        </Stack>
        <Stack
          sx={{ alignItems: 'center', flexDirection: 'row', gap: 1 }}
        >
          {endContent}
          {onClose && (
            <IconButton
              sx={{ mr: -1.5, my: -1 }}
              onClick={onClose}
              disabled={disableClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </DialogTitle>
  )
}
