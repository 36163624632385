import {
  DurationValue,
  getDurationLabel,
} from 'components/DurationField'

export const PREDRAFT_VERSION_ID = 'PREDRAFT_VERSION_ID'
export const nestStandardAccountId = 'nest-standard-care-plans'

export function isNestStandardAccount(id: string) {
  return id === nestStandardAccountId
}

export function getDueLabel(value: DurationValue) {
  if (!value) {
    return null
  }
  return `Due in ${getDurationLabel(value)}`
}
