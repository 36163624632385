import { useId, useMemo } from 'react'
import {
  PopupState,
  bindDialog,
  usePopupState,
} from 'material-ui-popup-state/hooks'

export default function useDialog(): [
  ReturnType<typeof bindDialog> & {
    onClose: (event?: React.SyntheticEvent<unknown, Event>) => void
  },
  {
    open: boolean
    setOpen: PopupState['setOpen']
  },
] {
  const popupState = usePopupState({
    popupId: useId(),
    variant: 'dialog',
  })
  const result = useMemo<ReturnType<typeof useDialog>>(
    () => [
      bindDialog(popupState),
      {
        open: popupState.isOpen,
        setOpen: popupState.setOpen,
      },
    ],
    [popupState],
  )
  return result
}
