import { PaletteMode } from '@mui/material'
import localforage from 'localforage'

type SettingsState = {
  themeMode: PaletteMode
}

type SettingsStoreKey = keyof SettingsState

export default class SettingsStore {
  static store = localforage.createInstance({
    name: 'settings',
  })

  static get<T extends SettingsStoreKey>(key: T) {
    SettingsStore.log('get', key)
    return this.store.getItem<SettingsState[T]>(key)
  }

  static set<T extends SettingsStoreKey>(
    key: T,
    value: SettingsState[T],
  ) {
    SettingsStore.log('set', key, value)
    return this.store.setItem(key, value)
  }

  static reset() {
    SettingsStore.log('reset')
    return this.store.clear()
  }

  static log(message, ...rest) {
    console.log(
      '%cSettingsStore',
      'border-radius:4px; padding:0 4px; color:white; background-color:#2480ec;',
      message,
      ...rest,
    )
  }
}
