import React, { ReactNode } from 'react'
import Link from 'next/link'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  SxProps,
} from '@mui/material'

export default function RouterLink(props: {
  href: string
  title?: string
  button?: boolean
  ButtonProps?: ButtonProps
  children: ReactNode
  color?: BoxProps['color']
  sx?: SxProps
}) {
  return props.button ? (
    <Button
      LinkComponent={Link}
      color="inherit"
      href={props.href}
      {...props.ButtonProps}
      title={props.title}
      sx={props.sx ?? props.ButtonProps?.sx}
    >
      {props.children}
    </Button>
  ) : (
    <Box
      component={Link}
      href={props.href}
      passHref
      title={props.title}
      sx={props.sx}
      color={props.color}
    >
      {props.children}
    </Box>
  )
}
