import JSConfetti from 'js-confetti'
import { useMemo } from 'react'

let jsConfetti
let canvas

export default function useConfetti() {
  const confetti = useMemo(() => {
    if (!canvas) {
      canvas = document.createElement('canvas')
      canvas.style.position = 'fixed'
      canvas.style.width = '100%'
      canvas.style.height = '100%'
      canvas.style.top = '0'
      canvas.style.left = '0'
      canvas.style.zIndex = '1301'
      canvas.style.pointerEvents = 'none'
      document.body.appendChild(canvas)
    }
    if (!jsConfetti) {
      jsConfetti = new JSConfetti({
        canvas,
      })
    }

    return {
      addConfetti: () => {
        jsConfetti.addConfetti({
          confettiColors: [
            '#e76545',
            '#67568c',
            '#e4795f',
            '#fbdd74',
          ],
        })
      },
    }
  }, [])

  return confetti
}
