import { useRouter } from 'next/router'

const NON_AUTH_ROUTE_REGEX = [
  /\/sso\/.+/,
  /\/activate.+/,
  /\/login.+/,
  /\/care-management-guides(.+)?/,
]
const UPLOADS_ROUTE_REGEX = [/\/uploads\/.+/]
const DELEGATE_ROUTE_REGEX = [/\/patients\/.*.\/assessments\/.+/]

export default function useRouteCheck(): {
  isAuthRequired: boolean
  isDelegateAllowed: boolean
  isUploadedContent: boolean
} {
  const router = useRouter()
  const isAuthRequired = !NON_AUTH_ROUTE_REGEX.some((regex) =>
    router.asPath.match(regex),
  )
  const isUploadedContent = !!UPLOADS_ROUTE_REGEX.some((regex) =>
    router.asPath.match(regex),
  )

  const isDelegateAllowed = !!DELEGATE_ROUTE_REGEX.some((regex) =>
    router.asPath.match(regex),
  )
  return { isAuthRequired, isDelegateAllowed, isUploadedContent }
}
