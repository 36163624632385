import React from 'react'
import {
  Backdrop,
  BackdropProps,
  Box,
  CircularProgress,
  Portal,
  Typography,
} from '@mui/material'

export default function LoadingBackdrop(
  props: BackdropProps & { progress?: number },
) {
  const { progress, ...backdropProps } = props
  const variant =
    progress !== undefined && progress >= 0
      ? 'determinate'
      : 'indeterminate'

  return (
    <Portal>
      <Backdrop
        {...backdropProps}
        sx={{
          backgroundColor: backdropProps.invisible
            ? null
            : 'rgba(0, 0, 0, 0.25)',
          zIndex: 1500,
          ...backdropProps.sx,
        }}
      >
        <CircularProgress
          color="primary"
          variant={variant}
          value={progress}
        />
        {variant === 'determinate' && (
          <Box
            sx={{
              alignItems: 'center',
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="secondary"
            >
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        )}
      </Backdrop>
    </Portal>
  )
}
