import React, { useContext, useRef, useState } from 'react'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  DialogContent,
  Grow,
  Menu,
  MenuItem,
  Stack,
  StackProps,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import DropDownIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { useRouter } from 'next/router'
import RouterLink from 'components/RouterLink'
import { PageContext } from 'components/PageContext'
import SettingsDrawer from 'components/SettingsDrawer'
import { mixins } from 'components/NestMuiTheme'
import {
  AdminPortal,
  useAuthUser,
  useLoginActions,
} from '@frontegg/react'
import UserModel from 'models/User'
import AccountModel from 'models/Account'
import packageJson from '../../package.json'
import useConfetti from 'hooks/useConfetti'
import { useKeys } from 'rooks'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import useDialog from 'hooks/useDialog'
import Link from 'next/link'
import TenantSelector from './TenantSelector'
import useAuthTenants from 'hooks/useAuthTenants'

const CARE_STUDIO_URL = '/care-studio'
const CARE_STUDIO_DEFAULT_URL = '/care-studio/care-templates'
const PORTAL_DEFAULT_URL = '/patients'

export default function MainNavBar(): JSX.Element {
  const router = useRouter()
  const { logout } = useLoginActions()
  const authUser = useAuthUser()
  const { currentUser, account } = useContext(PageContext)
  const userButton = useRef<HTMLButtonElement>(null)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
  const handleUserMenuClose = () => setUserMenuOpen(false)
  const isCareStudioUrl = !!router.pathname.match(CARE_STUDIO_URL)
  const {
    isAdmin,
    isDelegate,
    isNestStandardUser,
    isMultiTenant,
    switchTenant,
  } = useAuthTenants()
  const [
    switchAccountDialogProps,
    { setOpen: setSwitchAccountDialogOpen },
  ] = useDialog()
  const confetti = useConfetti()
  const getActiveSx = (matcher: string | RegExp): SxProps<Theme> => ({
    ...(router.pathname.match(matcher) && {
      '&:after': {
        bgcolor: 'primary.main',
        bottom: 0,
        content: '""',
        height: 2,
        position: 'absolute',
        width: 1,
      },
    }),
    alignSelf: 'stretch',
    borderRadius: 0,
    color: 'text.primary',
    px: 1,
  })

  useKeys(['AltLeft', 'KeyW'], () => setSwitchAccountDialogOpen(true))

  return (
    <AppBar
      position="sticky"
      color="transparent"
      sx={[
        {
          bgcolor: 'background.default',
          boxShadow: ({ palette }) => `0 1px 0 ${palette.divider}`,
          whiteSpace: 'nowrap',
        },
      ]}
    >
      <Toolbar
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          height: 64,
          justifyContent: 'space-between',
          overflow: 'scroll',
          width: 1,
        }}
      >
        <Stack
          direction="row"
          gap={4}
          sx={{ alignItems: 'stretch', alignSelf: 'stretch' }}
        >
          <Stack
            direction="row"
            gap={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                alignSelf: 'center',
                cursor: 'pointer',
                gap: 1,
                ml: -0.5,
              }}
            >
              <RouterLink href="/">
                <Box
                  component="img"
                  src="/img/nest-symbol.svg"
                  alt="Nest Logo"
                  height={24}
                  width={24}
                  sx={{ display: 'block' }}
                />
              </RouterLink>
              <Box
                sx={{
                  '& .MuiCollapse-root': {
                    height: 1,
                    position: 'absolute',
                    top: 0,
                  },
                  height: 20,
                  minWidth: 96,
                  position: 'relative',
                }}
              >
                <Collapse
                  orientation="horizontal"
                  in={!isCareStudioUrl}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Grow in={!isCareStudioUrl}>
                    <Stack direction="row" sx={{ width: 96 }}>
                      <Box
                        component="img"
                        src="/img/nest-text.svg"
                        alt="Nest"
                        height={20}
                        width={58}
                      />
                      <Box sx={{ flexGrow: 1 }} />
                    </Stack>
                  </Grow>
                </Collapse>
                <Collapse
                  orientation="horizontal"
                  in={isCareStudioUrl}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Grow in={isCareStudioUrl}>
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{ fontWeight: 700, letterSpacing: 0 }}
                    >
                      CARE STUDIO
                    </Typography>
                  </Grow>
                </Collapse>
              </Box>
            </Stack>
          </Stack>
          {!isDelegate && !isCareStudioUrl && (
            <Stack
              direction="row"
              gap={3}
              sx={{ overflow: 'hidden' }}
            >
              <RouterLink
                href={'/dashboard'}
                button
                sx={getActiveSx(/\/$/)}
              >
                <Typography variant="body2">Dashboard</Typography>
              </RouterLink>
              <RouterLink
                href="/activity"
                button
                sx={getActiveSx('/activity')}
              >
                <Typography variant="body2">Activity</Typography>
              </RouterLink>
              <RouterLink
                href="/patients"
                button
                sx={getActiveSx('/patients')}
              >
                <Typography variant="body2">Patients</Typography>
              </RouterLink>
              <RouterLink
                href="/inbox"
                button
                sx={getActiveSx('/inbox')}
              >
                <Typography variant="body2">Inbox</Typography>
              </RouterLink>
            </Stack>
          )}
          {isCareStudioUrl && (
            <Stack
              direction="row"
              gap={3}
              sx={{ overflow: 'hidden' }}
            >
              <RouterLink
                href={'/care-studio/care-templates'}
                button
                sx={getActiveSx('/care-studio/care-templates')}
              >
                <Typography variant="body2">
                  Care Templates
                </Typography>
              </RouterLink>
              {isNestStandardUser && (
                <RouterLink
                  href="/care-studio/care-step-library"
                  button
                  sx={getActiveSx('/care-studio/care-step-library')}
                >
                  <Typography variant="body2">
                    Care Step Library
                  </Typography>
                </RouterLink>
              )}
              <RouterLink
                href="/care-studio/document-templates"
                button
                sx={getActiveSx('/care-studio/document-templates')}
              >
                <Typography variant="body2">
                  Document Templates
                </Typography>
              </RouterLink>
              {isNestStandardUser && (
                <RouterLink
                  href="/care-studio/rule-engines"
                  button
                  sx={getActiveSx('/care-studio/rule-engines')}
                >
                  <Typography variant="body2">
                    Rule Engines
                  </Typography>
                </RouterLink>
              )}
            </Stack>
          )}
        </Stack>
        {!isDelegate && (
          <>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                gap: 2,
              }}
            >
              {/*
          // TODO not yet implemented.
          <IconButton color="inherit">
            <Badge
              // badgeContent={2}
              invisible
              color="primary"
              overlap="circular"
              variant="dot"
            >
              <NotificationsNoneIcon fontSize="small" />
            </Badge>
          </IconButton> */}
              {!isCareStudioUrl && (
                <Button
                  href={CARE_STUDIO_DEFAULT_URL}
                  LinkComponent={Link}
                  color="secondary"
                  variant="text"
                  size="small"
                >
                  Go to Care Studio
                </Button>
              )}
              {isCareStudioUrl && (
                <Button
                  href={PORTAL_DEFAULT_URL}
                  LinkComponent={Link}
                  color="secondary"
                  variant="text"
                  size="small"
                >
                  Go to Portal
                </Button>
              )}

              <Button
                ref={userButton}
                endIcon={<DropDownIcon />}
                color="inherit"
                onClick={() => setUserMenuOpen(true)}
                sx={{
                  '& .MuiButton-endIcon': { ml: 0 },
                  '& .MuiButton-startIcon': { mr: 0.5 },
                  fontWeight: 400,
                }}
                startIcon={
                  <ProfileBar
                    user={currentUser}
                    account={account}
                    hideNames={!isMultiTenant}
                    sx={{
                      '& .MuiTypography-body1': {
                        fontSize: '.875rem',
                      },
                      '& .MuiTypography-caption': {
                        fontSize: '.75rem',
                      },
                    }}
                  />
                }
              />
              <Menu
                data-testid="account-menu"
                anchorEl={userButton.current}
                open={userMenuOpen}
                onClose={handleUserMenuClose}
                elevation={1}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                {!isMultiTenant && (
                  <MenuItem
                    onClick={() => {
                      handleUserMenuClose()
                      AdminPortal.show()
                    }}
                    divider={!isMultiTenant}
                  >
                    <ProfileBar
                      user={currentUser}
                      account={account}
                    />
                  </MenuItem>
                )}
                {isMultiTenant && (
                  <MenuItem
                    onClick={() => {
                      setSwitchAccountDialogOpen(true)
                      handleUserMenuClose()
                    }}
                  >
                    Switch Account
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleUserMenuClose()
                    AdminPortal.show()
                  }}
                >
                  Account Settings
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleUserMenuClose()
                    setSettingsDrawerOpen(true)
                  }}
                  divider
                >
                  Preferences
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    onClick={() => {
                      handleUserMenuClose()
                      router.push('/internal/ehr-launcher')
                    }}
                    divider
                  >
                    EHR Launcher
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleUserMenuClose()
                    logout()
                  }}
                  divider
                >
                  Log out
                </MenuItem>
                <MenuItem
                  sx={{
                    color: 'text.secondary',
                    fontSize: '.75rem',
                    mb: -1,
                    opacity: 0.6,
                  }}
                  onClick={confetti.addConfetti}
                >
                  Version {packageJson.version}
                </MenuItem>
              </Menu>
            </Stack>
          </>
        )}
      </Toolbar>
      <SettingsDrawer
        open={settingsDrawerOpen}
        onClose={() => setSettingsDrawerOpen(false)}
      />
      <EnhancedDialog
        {...switchAccountDialogProps}
        disableCloseOnBackdropClick
        maxWidth="xs"
        fullWidth
      >
        <EnhancedDialogTitle
          onClose={() => setSwitchAccountDialogOpen(false)}
        >
          Switch Account
        </EnhancedDialogTitle>
        <DialogContent>
          <TenantSelector
            selectedTenantId={authUser.tenantId}
            onChange={(tenantId) => {
              router.push(
                isCareStudioUrl
                  ? CARE_STUDIO_DEFAULT_URL
                  : PORTAL_DEFAULT_URL,
              )
              switchTenant({ tenantId })
            }}
          />
        </DialogContent>
      </EnhancedDialog>
    </AppBar>
  )
}

type ProfileBarProps = {
  user: UserModel
  account: AccountModel
  hideNames?: boolean
  sx?: StackProps['sx']
}

function ProfileBar(props: ProfileBarProps) {
  const { user, account, hideNames } = props
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: 1,
        maxWidth: 320,
        whiteSpace: 'normal',
        ...props.sx,
      }}
    >
      {user.profilePictureUrl && (
        <Avatar
          alt={user.name}
          src={user.profilePictureUrl}
          sx={{ height: 32, width: 32 }}
        />
      )}
      {!hideNames && (
        <Stack
          sx={{
            '& .MuiTypography-root': {
              ...mixins.textOverflowEllipsis,
              lineHeight: 1.25,
              maxWidth: 160,
              textAlign: 'left',
            },
          }}
        >
          <Typography variant="body1">{user.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {account.getTenantName()}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}
