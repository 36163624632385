import React, { ReactNode } from 'react'
import {
  FronteggProvider,
  FronteggProviderProps,
} from '@frontegg/react'
import { useRouter } from 'next/router'

const contextOptions: FronteggProviderProps['contextOptions'] = {
  baseUrl: process.env.NEXT_PUBLIC_AUTH_SERVICE_URI,
  clientId: process.env.NEXT_PUBLIC_AUTH_SERVICE_CLIENTID,
}

type NestFronteggProviderProps = {
  children: ReactNode
}

export default function NestFronteggProvider(
  props: NestFronteggProviderProps,
) {
  const router = useRouter()

  // In the patient app this was labeled as:
  // Fix for mysterious error from either frontegg or nextjs...
  //
  // Going to keep it there as well for a feature parity.
  if (typeof document === 'undefined') {
    return
  }

  return (
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox={false}
      authOptions={{
        includeQueryParam: true,
        keepSessionAlive: true,
      }}
      // This change the history object to use the nextjs router.
      history={{
        push: (pathOrLocation: string, state?) => {
          if (typeof pathOrLocation === 'string') {
            router.push(pathOrLocation, state)
          } else {
            router.push(pathOrLocation)
          }
        },
        replace: (pathOrLocation: string, state?) => {
          if (typeof pathOrLocation === 'string') {
            router.replace(pathOrLocation, state)
          } else {
            router.replace(pathOrLocation)
          }
        },
      }}
    >
      {props.children}
    </FronteggProvider>
  )
}
