import { Account } from '__generated__/graphql'
import { TenantsState, User } from '@frontegg/redux-store'

export function getNewAccount(): Account {
  return {
    CareTemplates: [],
    _count: undefined,
    createdAt: new Date().toISOString(),
    id: '-',
    lang: 'en-US',
    name: '',
    removed: false,
    settings: {},
    storySettings: {},
    timezone: 'America/Los_Angeles',
    updatedAt: new Date().toISOString(),
  }
}

export default class AccountModel extends Account {
  authUser: User = null
  tenantsState: TenantsState = null
  constructor(
    data: Account,
    authUser?: User,
    tenantsState?: TenantsState,
  ) {
    super()
    Object.assign(this, data ?? getNewAccount())
    this.authUser = authUser
    this.tenantsState = tenantsState
  }

  getTenantName() {
    return this.tenantsState?.activeTenant?.name
  }
}
