import React, { ReactNode, useCallback } from 'react'
import { Alert, Button, Snackbar } from '@mui/material'
import { atom, useRecoilState } from 'recoil'

type Severity = 'error' | 'info' | 'success' | 'warning' | null
export type AppSnackbarState = {
  message?: ReactNode
  open?: boolean
  severity?: Severity
  action?: {
    label: string
    onClick: () => void
  }
}

export const appSnackbarState = atom<AppSnackbarState>({
  default: {
    action: {
      label: '',
      onClick: () => {},
    },
    message: '',
    open: false,
    severity: undefined,
  },
  key: 'appSnackbar',
})

export function useAppSnackbar() {
  const [appSnackbar, setAppSnackbar] =
    useRecoilState(appSnackbarState)
  const show = useCallback(
    (state: AppSnackbarState) => {
      setAppSnackbar({
        action: {
          label: state?.action?.label ?? '',
          onClick: state?.action?.onClick ?? (() => {}),
        },
        message: state?.message ?? '',
        open: state?.open ?? true,
        severity: state?.severity ?? undefined,
      })
    },
    [setAppSnackbar],
  )

  return {
    action: appSnackbar.action,
    message: appSnackbar.message,
    open: appSnackbar.open,
    severity: appSnackbar.severity,
    show,
  }
}

export default function AppSnackbar() {
  const { open, show, severity, message, action } = useAppSnackbar()
  const { label: actionLabel, onClick: onActionClick } = action
  const handleClose = () =>
    show({ action, message, open: false, severity })
  const handleActionClick = () => {
    onActionClick?.()
    handleClose()
  }

  return (
    <Snackbar
      data-testid="app-snackbar"
      open={open}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      autoHideDuration={8000}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        onClose={handleClose}
        sx={{
          '& .MuiAlert-action': { pt: 0 },
          alignItems: 'center',
          boxShadow: 1,
        }}
        action={
          actionLabel ? (
            <Button
              color="inherit"
              size="small"
              onClick={handleActionClick}
            >
              {actionLabel}
            </Button>
          ) : null
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
