import { PaletteMode, alpha } from '@mui/material'
import { PaletteOptions, createTheme } from '@mui/material/styles'
import { backdropClasses } from '@mui/material/Backdrop'
// Added the following config changes to get `themeAugmentation` to build:
// - nextjs.config.typescript.ignoreBuildErrors: true
import type {} from '@mui/x-data-grid/themeAugmentation'
import chroma from 'chroma-js'

const defaultPrimaryColor = '#E75732'
const errorColor = '#EF5350'
const getPalettes = (
  primaryColor = defaultPrimaryColor,
): {
  light: PaletteOptions
  dark: PaletteOptions
} => ({
  dark: {
    ...createTheme({ palette: { mode: 'dark' } }).palette,
    background: {
      default: 'rgb(31, 32, 35)',
      paper: 'rgb(42, 43, 46)',
    },
    error: {
      main: errorColor,
    },
    mode: 'dark',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: 'rgba(255,255,255,0.5)',
    },
    text: {
      disabled: 'rgba(255,255,255,0.33)',
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255,255,255,0.5)',
    },
  },
  light: {
    ...createTheme().palette,
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
    error: {
      main: errorColor,
    },
    mode: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: 'rgba(0,0,0,0.5)',
    },
    text: {
      disabled: 'rgba(0,0,0,0.33)',
      primary: 'rgba(0, 0, 0, 0.72)',
      secondary: 'rgba(0,0,0,0.5)',
    },
  },
})

export function getTheme(
  mode: PaletteMode,
  options?: {
    fontSize?: number
    primaryColor?: string
  },
) {
  const { primaryColor = defaultPrimaryColor, fontSize = 14 } =
    options ?? {}
  const palettes = getPalettes(primaryColor)
  const palette: PaletteOptions = palettes[mode]
  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            // Hide option with empty label.
            '&:empty': { display: 'none' },
            fontSize: '0.875rem',
          },
        },
      },
      // Fix backdrop issue (workaround)
      // ref: https://github.com/mui/material-ui/issues/32286#issuecomment-1820685334
      MuiBackdrop: {
        styleOverrides: {
          root: {
            '&[style*="opacity: 0"]': {
              pointerEvents: 'none',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
        variants: [
          {
            props: {
              variant: 'tertiary',
            },
            style: {
              '&:hover': {
                backgroundColor: chroma(primaryColor)
                  .alpha(0.15)
                  .css(),
              },
              backgroundColor: chroma(primaryColor).alpha(0.1).css(),
              color: primaryColor,
              textTransform: 'none',
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          iconSmaller: {
            fontSize: '1.125rem',
          },
          label: {
            fontWeight: 500,
          },
          labelSmaller: {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
        variants: [
          {
            props: { size: 'smaller' },
            style: {
              '& > .MuiChip-label': {
                paddingLeft: 8,
                paddingRight: 8,
              },
              fontSize: '0.75rem',
              height: 20,
            },
          },
        ],
      },
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: primaryColor,
          },
          body: {
            '#__next': {
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            },
            backgroundColor: palette.background.default,
            overflowX: 'hidden',
            overflowY: 'auto',
            overscrollBehaviorX: 'none',
          },
          html: {
            // Global responsive font sizes for small viewports.
            '@media (max-height: 768px)': { fontSize },
            '@media (max-width: 768px)': { fontSize },
            overscrollBehaviorX: 'none',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
            fontSize: '.8125rem',
            fontWeight: 600,
            textTransform: 'uppercase',
          },
          iconSeparator: {
            display: 'none',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: 'none',
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          variant: 'h5',
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '& .MuiTouchRipple-child': {
              transform: 'scale(1.3)',
            },
            borderRadius: '0.2em',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::-webkit-outer-spin-button,::-webkit-inner-spin-button':
              {
                appearance: 'none',
                margin: 0,
              },
            '&:autofill': {
              // Override Safari's yellow autofill text color.
              WebkitBoxShadow: 'inherit !important',
              WebkitTextFillColor: 'inherit !important',
              caretColor: 'inherit !important',
              color: 'inherit !important',
            },
            '&[type=number]': {
              appearance: 'textfield',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '.875rem',
            fontWeight: 500,
            marginBottom: 4,
            textWrap: 'pretty',
            zIndex: 0,
          },
          shrink: {
            position: 'relative',
            transform: 'none',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: '1rem',
          },
          secondary: {
            fontSize: '.875rem',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            fontSize: '.8125rem',
            fontWeight: 600,
            textTransform: 'uppercase',
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 1,
          transitionDuration: 0,
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
          },
        },
      },
      // Fix Modal backdrop issue (workaround)
      // ref: https://github.com/mui/material-ui/issues/32286#issuecomment-1820685334
      MuiModal: {
        styleOverrides: {
          root: {
            [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]:
              {
                pointerEvents: 'none',
              },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiAlert-action': {
              marginRight: 0.1,
            },
            '& button.MuiButton-root': {
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          subtitle1: {
            fontWeight: 500,
          },
          subtitle2: {
            fontWeight: 500,
          },
        },
      },
    },
    palette,
    shape: {
      borderRadius: 6,
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: [
        'Inter',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize,
      fontWeightBold: 600,
      h1: {
        fontSize: '1.875rem',
        fontWeight: 400,
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '1.625rem',
        fontWeight: 400,
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '1.375rem',
        fontWeight: 400,
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '.875rem',
        fontWeight: 500,
        lineHeight: 1.4,
      },
      subtitle2: {
        fontWeight: 5700,
      },
    },
  })
  return theme
}

export const mixins = {
  bgBlur: {
    backdropFilter: 'blur(4px)',
    bgcolor: ({ palette }) => alpha(palette.background.default, 0.95),
  },
  scrollHorizontal: {
    '&::-webkit-scrollbar': {
      display: 'none',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    MsOverflowStyle: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
  },
  scrollVertical: {
    '&::-webkit-scrollbar': {
      display: 'none',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    MsOverflowStyle: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  textOverflowEllipsis: {
    '> *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textOverflowEllipsisMultiline: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}

export function getBackgroundColorForMode(
  bgcolor: string,
  mode: 'light' | 'dark',
) {
  const lightness = mode === 'light' ? 0.9925 : 0.15

  return chroma(bgcolor).set('hsl.l', lightness).css()
}

export function getPreferredThemeMode() {
  return window?.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
}

// Adding custom typography variants.
// https://mui.com/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tertiary: true
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    smaller: true
  }
  interface ChipClasses {
    iconSmaller: true
    labelSmaller: true
  }
}
