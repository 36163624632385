import React, { useEffect, useMemo, useState } from 'react'
import { AppProps } from 'next/app'
import AppBase from 'components/AppBase'
import CssBaseline from '@mui/material/CssBaseline'
import { PaletteMode, ThemeProvider } from '@mui/material'
import { RecoilRoot } from 'recoil'
import Head from 'next/head'
import NestFronteggProvider from 'components/NestFronteggProvider'
import {
  getPreferredThemeMode,
  getTheme,
} from 'components/NestMuiTheme'
import SettingsStore from 'stores/SettingsStore'
import useUnsupportedBrowser, {
  UnsupportedBrowserMessage,
} from 'hooks/useUnsupportedBrowser'
import TrackerProvider from 'components/TrackerContext'
import { MainApolloProvider } from 'components/ApolloClient'
import { useRouter } from 'next/router'

export default function App({ Component, pageProps }: AppProps) {
  const [appReady, setAppReady] = useState(false)
  const [themeMode, setThemeMode] = useState<PaletteMode>('light')
  const isUnsupportedBrowser = useUnsupportedBrowser()
  const theme = useMemo(() => getTheme(themeMode), [themeMode])
  const router = useRouter()

  // Load initial data from stores.
  useEffect(() => {
    SettingsStore.get('themeMode').then((themeMode) => {
      setThemeMode(themeMode ?? getPreferredThemeMode())
      setAppReady(true)
    })
  }, [])

  // Allow deep linking to work for initial page load.
  useEffect(() => {
    if (router.isReady) {
      router.replace(router.asPath).catch((e) => {
        // Avoids Cancel rendering route errors.
        // Workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  if (!appReady) {
    return null
  }

  if (isUnsupportedBrowser) {
    return <UnsupportedBrowserMessage />
  }

  return (
    <NestFronteggProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Head>
          <title>Sign In - Nest</title>
        </Head>
        <RecoilRoot>
          <TrackerProvider>
            <MainApolloProvider>
              <AppBase
                themeMode={themeMode}
                setThemeMode={setThemeMode}
              >
                <Component {...pageProps} />
              </AppBase>
            </MainApolloProvider>
          </TrackerProvider>
        </RecoilRoot>
      </ThemeProvider>
    </NestFronteggProvider>
  )
}
