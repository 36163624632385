import { useAuth, useTenantsState } from '@frontegg/react'
import { Box, PaletteMode } from '@mui/material'
import AppSnackbar from 'components/AppSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import MainNavBar from 'components/MainNavBar'
import { PageContext } from 'components/PageContext'
import useAutoLogout, { StoreKey } from 'hooks/useAutoLogout'
import useRouteCheck from 'hooks/useRouteCheck'
import AccountModel from 'models/Account'
import UserModel from 'models/User'
import ForbiddenPage from 'pages/forbidden'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Environment, SelectOption } from 'types/Global'
import UserUtil from 'utils/UserUtil'
import {
  Account,
  useAreaOfConcernsQuery,
  useLabsQuery,
  useLoggedInAccountLazyQuery,
} from '__generated__/graphql'
import SessionExpiredDialog from './AutoLogout/SessionExpiredDialog'
import SessionInactiveDialog from './AutoLogout/SessionInactiveDialog'
import PendoScript from './PendoScript'
import { TrackerContext } from './TrackerContext'
import { sanitize } from 'isomorphic-dompurify'

const ACTIVITY_EXPIRATION =
  process.env.NODE_ENV === Environment.DEVELOPMENT
    ? 3600000 * 12 // 12 hours
    : 60000 * 30 // 30 min

type AppBaseProps = {
  themeMode: PaletteMode
  setThemeMode: (mode: PaletteMode) => void
  children: ReactNode
}

export default function AppBase(props: AppBaseProps) {
  const { themeMode, setThemeMode } = props
  const { user, isAuthenticated } = useAuth()
  const tenantsState = useTenantsState()
  const { initTracker } = useContext(TrackerContext)
  const [account, setAccount] = useState(
    new AccountModel(null, user, tenantsState),
  )
  const [fetchLoggedInAccount, { data: accountResult }] =
    useLoggedInAccountLazyQuery()
  const isInIframe = window.location !== window.parent.location
  const [loadingBackdropOpen, setLoadingBackdropOpen] =
    useState(false)
  const { data: labsData } = useLabsQuery({
    skip: !isAuthenticated,
  })
  const labOptions: SelectOption[] = (labsData?.labs ?? []).map(
    (lab) => ({
      label: lab.name,
      value: lab.id,
    }),
  )
  const { data: areaOfConcernsData } = useAreaOfConcernsQuery({
    skip: !isAuthenticated,
  })
  const areaOfConcernOptions =
    areaOfConcernsData?.areaOfConcerns ?? []
  const { isAuthRequired, isDelegateAllowed, isUploadedContent } =
    useRouteCheck()
  const {
    logout,
    sessionIsExpired,
    sessionIsInactive,
    setSessionIsInactive,
  } = useAutoLogout(ACTIVITY_EXPIRATION, !isAuthRequired)

  const isSuperUser = !!user?.superUser
  const hasPortalRoleAccess = UserUtil.hasPortalRoleAccess(user)

  useEffect(() => {
    initTracker()
  }, [initTracker])

  useEffect(() => {
    if (isAuthenticated) {
      fetchLoggedInAccount()
    }
  }, [fetchLoggedInAccount, isAuthenticated])

  useEffect(() => {
    const loggedInAccount = accountResult?.portalApp.loggedInAccount

    if (loggedInAccount) {
      setAccount(
        new AccountModel(
          loggedInAccount as Account,
          user,
          tenantsState,
        ),
      )
    }
  }, [accountResult?.portalApp.loggedInAccount, user, tenantsState])

  // Avoid app render until user is loaded and authenticated.
  if (isAuthRequired && (!isAuthenticated || !user)) {
    return null
  }

  // Display forbidden for users with insufficient roles.
  if (
    !isSuperUser &&
    isAuthRequired &&
    (!hasPortalRoleAccess ||
      (UserUtil.hasDelegateRole(user) && !isDelegateAllowed))
  ) {
    if (isDelegateAllowed) {
      // Check for redirect before rendering forbidden page
      const redirectUrl = localStorage.getItem(
        StoreKey.REDIRECT_AFTER_LOGIN,
      )
      if (redirectUrl) {
        localStorage.removeItem(StoreKey.REDIRECT_AFTER_LOGIN)
        const sanitizedUrl = sanitize(redirectUrl, {
          ALLOWED_URI_REGEXP:
            /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i, // eslint-disable-line no-useless-escape
        })
        window.location.href = sanitizedUrl as string
      }
    }
    return <ForbiddenPage />
  }

  return (
    <PageContext.Provider
      value={{
        account,
        areaOfConcernOptions,
        currentUser: new UserModel(user),
        isInIframe,
        labOptions,
        loadingBackdropOpen,
        setAccount,
        setLoadingBackdropOpen,
        setThemeMode,
        themeMode,
      }}
    >
      {isAuthRequired && !isInIframe && !isUploadedContent && (
        <MainNavBar />
      )}
      <Box
        id="main"
        component="main"
        sx={[
          {
            display: 'flex',
            flexGrow: 1,
            mx: 'auto',
            position: 'relative',
            width: 1,
          },
        ]}
      >
        {props.children}
      </Box>
      <AppSnackbar />
      <SessionExpiredDialog
        onClose={logout}
        open={sessionIsExpired}
      />
      <SessionInactiveDialog
        onConfirm={logout}
        onClose={() => setSessionIsInactive(false)}
        open={sessionIsInactive}
      />
      <PendoScript />
      <LoadingBackdrop open={loadingBackdropOpen} />
    </PageContext.Provider>
  )
}
