import { noop } from 'lodash'
import React from 'react'
import { PaletteMode } from '@mui/material'
import AccountModel from 'models/Account'
import UserModel from 'models/User'
import { SelectOption } from 'types/Global'

export const PageContext = React.createContext({
  account: null as AccountModel,
  areaOfConcernOptions: [] as SelectOption[],
  currentUser: new UserModel(),
  isInIframe: false,
  labOptions: [] as SelectOption[],
  loadingBackdropOpen: false,
  setAccount: noop,
  setLoadingBackdropOpen: noop,
  setThemeMode: noop as (mode: PaletteMode) => void,
  themeMode: 'light' as PaletteMode,
})
