import ConfirmationDialog from 'components/ConfirmationDialog'
import React, { ReactNode, useCallback } from 'react'
import { useWindowEventListener } from 'rooks'

const AUTO_CLOSE_DELAY = 1000 * 10 // 10 sec

export type SessionExpiredDialogProps = {
  onClose: () => void
  open: boolean
  title?: string
  message?: ReactNode
  loginLabel?: string
  autoClose?: boolean
}

export default function SessionExpiredDialog({
  onClose,
  open,
  title = 'Your session expired',
  message = 'For your security, you were automatically logged out due to inactivity.',
  loginLabel = 'Log in again',
  autoClose = true,
}: SessionExpiredDialogProps) {
  const handleFocus = useCallback(() => {
    if (autoClose && open) {
      window.setTimeout(onClose, AUTO_CLOSE_DELAY)
    }
  }, [autoClose, onClose, open])

  useWindowEventListener('focus', handleFocus)

  return (
    <ConfirmationDialog
      open={open}
      title={title}
      message={message}
      confirmLabel={loginLabel}
      onConfirm={onClose}
      onClose={onClose}
      BackdropProps={{ sx: { backdropFilter: 'blur(5px)' } }}
    />
  )
}
