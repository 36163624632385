import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import TextField, { TextFieldProps } from 'components/TextField'
import DropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClearIcon from '@mui/icons-material/ClearRounded'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { omit } from 'lodash'
import ErrorTooltip from 'components/ErrorTooltip'
import {
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks'

export type DurationTimeUnit = 'Y' | 'M' | 'W' | 'D'
export type Duration = `P${number}${DurationTimeUnit}`
export type DurationRange = `${Duration}-${Duration}`
export type DurationValue = Duration | DurationRange | ''

export const periodUnitOptions: {
  label: string
  labelSingle: string
  value: DurationTimeUnit
}[] = [
  {
    label: 'years',
    labelSingle: 'year',
    value: 'Y',
  },
  {
    label: 'months',
    labelSingle: 'month',
    value: 'M',
  },
  {
    label: 'weeks',
    labelSingle: 'week',
    value: 'W',
  },
  {
    label: 'days',
    labelSingle: 'day',
    value: 'D',
  },
]

function getTimeUnitLabel(
  unit: DurationTimeUnit,
  isPlural?: boolean,
) {
  const labelKey = isPlural ? 'label' : 'labelSingle'
  return (
    periodUnitOptions.find((opt) => opt.value === unit)?.[labelKey] ??
    'N/A'
  )
}
const getRange = (
  startLength: DurationValues['startLength'],
  endLength: DurationValues['endLength'],
  hasRange: boolean,
) => {
  return [startLength, hasRange ? endLength : null].filter(Boolean)
}
const getRangeValues = (value: string) =>
  (value?.match?.(/(\d+)/g) ?? []).map((val) => {
    const num = parseInt(val)
    return isNaN(num) ? undefined : num
  })
const getUnitFromDuration = (value: DurationValue) =>
  (value?.match?.(/P\d+([YMWD])/)?.[1] ?? 'Y') as DurationTimeUnit
const hasRangeFromDuration = (value: DurationValue) =>
  !!value?.match?.(/-/)

export function getDurationLabel(duration: DurationValue) {
  if (!duration) return 'N/A'
  const range = duration.match(/(\d+)/g).join('-')
  const isPlural = range !== '1'
  const rangeLabel = `${isPlural ? range : ''}`
  const timeUnitLabel = getTimeUnitLabel(
    duration.match(/[YMWD]/)[0] as DurationTimeUnit,
    isPlural,
  )
  return `${rangeLabel ? `${rangeLabel} ` : ''}${timeUnitLabel}`
}

const MIN_VALUE = 0
const MAX_VALUE = 999

type DurationValues = {
  endLength: number | null
  hasRange: boolean
  startLength: number | null
  unit: DurationTimeUnit
}

export type DurationFieldProps = {
  name?: TextFieldProps['name']
  disabled?: TextFieldProps['disabled']
  onBlur: TextFieldProps['onBlur']
  value: DurationValue
  onChange: (e: { name: string; value: DurationValue }) => void
  disableRange?: boolean
  label?: TextFieldProps['label']
  required?: TextFieldProps['required']
  asDisplay?: TextFieldProps['asDisplay']
  sx?: TextFieldProps['sx']
  error?: boolean
  minValue?: number
  maxValue?: number
}

export default function DurationField(props: DurationFieldProps) {
  const {
    onChange,
    disableRange,
    required,
    name,
    ...textFieldProps
  } = props
  const minValue = props.minValue ?? MIN_VALUE
  const maxValue = props.maxValue ?? MAX_VALUE
  const startLengthTextField = useRef<HTMLDivElement>()
  const rangeValues = getRangeValues(props.value)
  const formMethods = useForm<DurationValues>({
    defaultValues: {
      endLength: rangeValues[1],
      hasRange: !disableRange && hasRangeFromDuration(props.value),
      startLength: rangeValues[0],
      unit: getUnitFromDuration(props.value),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { control, handleSubmit, watch, reset, trigger } = formMethods
  const [isHovered, setIsHovered] = useState(false)
  const startLength = watch('startLength')
  const endLength = watch('endLength')
  const unit = watch('unit')
  const hasRange = watch('hasRange')
  const popupState = usePopupState({
    popupId: 'durationField',
    variant: 'popper',
  })
  const { isOpen } = popupState
  const value = useMemo(() => {
    const invalid = hasRange
      ? !startLength || !endLength
      : !startLength
    if (invalid) {
      return ''
    }
    return (
      hasRange
        ? `P${startLength}${unit}-P${endLength}${unit}`
        : `P${startLength}${unit}`
    ) as DurationValue
  }, [endLength, hasRange, startLength, unit])
  const valueAsLabel = useMemo(() => {
    const validRange = hasRange ? startLength < endLength : true
    const range = getRange(startLength, endLength, hasRange)
    if (validRange && range.length) {
      const isPlural = range.join('-') !== '1'
      const unitLabel = getTimeUnitLabel(unit, isPlural)
      return `${range.join('-')} ${unitLabel}` as DurationValue
    }
    return 'N/A'
  }, [endLength, hasRange, startLength, unit])
  const handleClear = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      reset({
        endLength: '' as unknown as number,
        startLength: '' as unknown as number,
      })
      onChange({ name, value: undefined })
    },
    [reset, onChange, name],
  )

  // Trigger validation on any field changes.
  useEffect(() => {
    trigger()
  }, [startLength, endLength, hasRange, trigger])

  useEffect(() => {
    const rangeValues = getRangeValues(props.value)
    reset({
      endLength: rangeValues[1] ?? ('' as unknown as number),
      hasRange: !disableRange && hasRangeFromDuration(props.value),
      startLength: rangeValues[0] ?? ('' as unknown as number),
      unit: getUnitFromDuration(props.value),
    })
  }, [disableRange, props.value, reset])

  useEffect(() => {
    if (isOpen) {
      const input =
        startLengthTextField.current.querySelector('input')
      // Focus on next render cycle.
      setTimeout(() => input.focus())
    }
  }, [isOpen])

  if (props.asDisplay) {
    return (
      <TextField
        label={props.label}
        value={valueAsLabel}
        sx={props.sx}
        error={props.error}
        asDisplay
      />
    )
  }

  return (
    <FormProvider {...formMethods}>
      <TextField
        {...omit(textFieldProps, [
          'minValue',
          'maxValue',
          'onBlur',
          'value',
        ])}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        value={valueAsLabel}
        {...bindToggle(popupState)}
        sx={{
          ...props.sx,
          '& .MuiOutlinedInput-root, &.MuiTextField-root .MuiOutlinedInput-input':
            {
              cursor: 'pointer',
            },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                '& .MuiButton-root': { mr: -0.25 },
              }}
            >
              <IconButton
                sx={{
                  p: 0.5,
                  position: 'relative',
                  visibility:
                    isHovered || isOpen ? 'visible' : 'hidden',
                  zIndex: 1301,
                }}
                onClick={handleClear}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <DropDownIcon
                sx={{
                  transform: isOpen ? 'scaleY(-1)' : null,
                }}
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        error={props.error}
      />
      <Popover
        open={isOpen}
        anchorEl={popupState.anchorEl}
        disablePortal
        onClose={handleSubmit(() => {
          onChange({ name, value })
          popupState.close()
          props.onBlur?.(null)
        })}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Paper
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            maxWidth: 400,
            p: 2,
          }}
        >
          <Stack gap={1}>
            <Stack direction="row" gap={1}>
              <Controller
                name="startLength"
                control={control}
                rules={{
                  max: {
                    message: `Must be less than ${
                      hasRange ? endLength : maxValue
                    }`,
                    value: hasRange ? endLength - 1 : maxValue,
                  },
                  min: {
                    message: `Must be greater than ${minValue}`,
                    value: minValue,
                  },
                  required,
                }}
                render={({ field, fieldState }) => (
                  <ErrorTooltip
                    title={fieldState.error?.message}
                    open
                  >
                    <TextField
                      ref={startLengthTextField}
                      type="number"
                      sx={{ minWidth: 88 }}
                      placeholder="0"
                      inputProps={{
                        max: maxValue,
                        min: minValue,
                      }}
                      {...omit(field, ['ref'])}
                      value={field.value ?? ''}
                      inputRef={field.ref} // Set `inputRef` to allow focus on errors.
                      error={!!fieldState.error}
                      onChange={({ target }) => {
                        field.onChange(parseInt(target.value))
                      }}
                    />
                  </ErrorTooltip>
                )}
              />
              {hasRange && <Box sx={{ py: 1 }}>to</Box>}
              {hasRange && (
                <Controller
                  name="endLength"
                  control={control}
                  rules={{
                    max: {
                      message: `Must be less than ${maxValue}`,
                      value: maxValue,
                    },
                    min: {
                      message: `Must be greater than ${Math.max(
                        minValue + 1,
                        startLength,
                      )}`,
                      value: minValue,
                    },
                    required: true,
                  }}
                  defaultValue={(startLength ?? 0) + 1}
                  render={({ field, fieldState }) => (
                    <ErrorTooltip
                      title={fieldState.error?.message}
                      open
                    >
                      <TextField
                        type="number"
                        sx={{ minWidth: 88 }}
                        placeholder="0"
                        inputProps={{
                          max: maxValue,
                          min: minValue + 1,
                        }}
                        {...omit(field, ['ref'])}
                        value={field.value ?? ''}
                        inputRef={field.ref} // Set `inputRef` to allow focus on errors.
                        required
                        error={!!fieldState.error}
                        onChange={({ target }) => {
                          field.onChange(parseInt(target.value))
                        }}
                      />
                    </ErrorTooltip>
                  )}
                />
              )}
              <Controller
                name="unit"
                control={control}
                rules={{ required: true }}
                defaultValue="Y"
                render={({ field, fieldState }) => (
                  <TextField
                    {...omit(field, ['ref'])}
                    value={field.value}
                    inputRef={field.ref} // Set `inputRef` to allow focus on errors.
                    options={periodUnitOptions}
                    sx={{ minWidth: 112 }}
                    select
                    required
                    error={!!fieldState.error}
                  />
                )}
              />
            </Stack>
            {!disableRange && (
              <Controller
                name="hasRange"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...omit(field, ['ref'])}
                    onChange={(e) => {
                      const target = e.target as HTMLInputElement
                      field.onChange(target.checked)
                    }}
                    inputRef={field.ref} // Set `inputRef` to allow focus on errors.
                    checked={field.value}
                    control={<Checkbox size="small" />}
                    label={
                      <Typography variant="caption">
                        Use time range
                      </Typography>
                    }
                    sx={{ mb: -1 }}
                  />
                )}
              />
            )}
          </Stack>
        </Paper>
      </Popover>
    </FormProvider>
  )
}
