import React from 'react'
import { Box, ListItem, Paper, Typography } from '@mui/material'
import ErrorSnackbar from 'components/ErrorSnackbar'
import { GraphQLFormattedError } from 'graphql/error'
import { Environment, GraphQLErrorCode } from 'types/Global'
import List from '@mui/material/List'
import { uniq } from 'lodash'
import { ClientError } from 'components/ApolloClient'

const getTitleForGraphQLErrors = (
  errors: readonly GraphQLFormattedError[],
) => {
  if (!errors) {
    return null
  }
  const uniqueCodes = uniq(
    errors.map((error) => error.extensions.code),
  )

  //If there is more than one error type then return a generic title
  if (uniqueCodes.length > 1) {
    return 'An error occurred. Please refresh the page or try again later.'
  }
  const code = errors.at(0).extensions.code
  switch (code) {
    case GraphQLErrorCode.BAD_USER_INPUT:
      return 'Invalid Input'
    case GraphQLErrorCode.FORBIDDEN:
      return 'Access Forbidden'
    case GraphQLErrorCode.UNAUTHENTICATED:
      return 'Your session has expired'
    default:
      return 'Unexpected error'
  }
}

const getDescriptionForGraphQLErrors = (
  errors: readonly GraphQLFormattedError[],
) => {
  if (!errors) {
    return []
  }
  const errorMessages = errors.map((error) => {
    switch (error.extensions.code) {
      case GraphQLErrorCode.UNAUTHENTICATED:
        return 'Please refresh the page and try again.'
      case GraphQLErrorCode.INTERNAL_SERVER_ERROR:
        return process.env.NODE_ENV !== Environment.PRODUCTION
          ? error.message
          : 'Please try again. If the problem persists please contact support at support@nestgenomics.com.'
      default:
        return error.message
    }
  })
  return uniq(errorMessages)
}

type ClientErrorSnackbarProps = {
  error?: ClientError
  open: boolean
  onClose: () => void
}

export default function ClientErrorSnackbar(
  props: ClientErrorSnackbarProps,
) {
  const { open, onClose } = props
  const { graphQLErrors, networkError, operation } = props.error ?? {}
  const title = networkError
    ? 'Oops, there was a problem requesting data'
    : getTitleForGraphQLErrors(graphQLErrors)

  return (
    <ErrorSnackbar open={open} onClose={onClose} title={title}>
      {networkError ? (
        process.env.NODE_ENV === Environment.PRODUCTION ? (
          'Please check your internet connection and try again.'
        ) : (
          networkError.message
        )
      ) : (
        <List disablePadding>
          {getDescriptionForGraphQLErrors(graphQLErrors).map(
            (description, index) => (
              <ListItem
                key={`graphQLErrorDescription${index}`}
                disableGutters
              >
                {description}
              </ListItem>
            ),
          )}
        </List>
      )}
      {process.env.NODE_ENV !== Environment.PRODUCTION &&
        graphQLErrors?.length && (
          <Paper
            sx={{
              bgcolor: 'transparent',
              color: 'inherit',
              maxHeight: 400,
              my: 2,
              overflow: 'auto',
              px: 3,
              py: 2,
              width: 1,
            }}
          >
            <Typography gutterBottom>
              <strong>GraphQL Error</strong>
            </Typography>
            {graphQLErrors?.map((item, index) => {
              const errorLines =
                item.locations?.map((loc) => loc.line) ?? []
              const isErrorLine = (index) =>
                errorLines.includes(index + 1)

              return (
                <Box component="pre" key={`graphQLError${index}`}>
                  <Box sx={{ whiteSpace: 'pre-wrap' }}>
                    {item.message}
                  </Box>
                  <Box sx={{ fontSize: '0.875em' }}>
                    {item.locations?.map((item, index) => (
                      <p key={`errorLocation${index}`}>
                        Line {item.line}, Column {item.column}
                      </p>
                    ))}
                    <pre>
                      <ol>
                        {operation?.query.loc.source.body
                          .split('\n')
                          .filter(Boolean)
                          .map((item, index) => (
                            <Box
                              key={`queryLine${index}`}
                              component="li"
                              sx={{
                                bgcolor: isErrorLine(index)
                                  ? 'rgba(255,255,255,.05)'
                                  : null,
                                borderRadius: 0.5,
                              }}
                            >
                              {item}
                            </Box>
                          ))}
                      </ol>
                    </pre>
                    {/* <p>
                    <Typography gutterBottom>
                      <strong>Stacktrace</strong>
                    </Typography>
                    <div>{item.extensions?.code}</div>
                    <pre>
                      {(
                        item.extensions?.exception as any
                      )?.stacktrace.join('\n')}
                    </pre>
                  </p> */}
                  </Box>
                </Box>
              )
            })}
          </Paper>
        )}
    </ErrorSnackbar>
  )
}
