import { gql, useLazyQuery } from 'components/ApolloClient'
import { PendoData } from '__generated__/graphql'

export function useLazyPendoData() {
  return useLazyQuery<{
    pendoData: PendoData
  }>(GQL.PendoData)
}

const GQL = {
  PendoData: gql`
    query PendoData {
      pendoData {
        account {
          id
          name
        }
        visitor {
          id
          age
          biologicalSex
          positiveGenes
          roles
          superUser
          vusGenes
        }
      }
    }
  `,
}
