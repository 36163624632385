import { User } from '__generated__/graphql'

export default class UserModel extends User {
  constructor(data?: Partial<User>) {
    super()
    Object.assign(this, data ?? {})
  }
  id: string
  tenantId: string
  firstName?: string
  lastName?: string
  profilePictureUrl?: string
  name? = ''
}
